import React from "react"

import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import ProductLandingPageAnotherSectionTwoWithBanner from "../../components/sections/ProductLandingPageAnotherSectionTwoWithBanner"

const MLRK1ARPage = () => {
  return (
    <MainContainer>
      <HeadDataNew
        title="MLRK1-AR"
        description="With this new version of the MLRK1, we’ve taken onwership of the quality control process so we can ensure that every product we sell
        features the Command Access level of quality we stand by."
        noIndex
      />

      <ProductLandingPageAnotherSectionTwoWithBanner />
    </MainContainer>
  )
}

export default MLRK1ARPage