import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import HubspotForm from 'react-hubspot-form'
import { navigate } from '@reach/router'

import YoutubePlayerLite from '../common/YoutubePlayerLite'
import PageHeaderImageBgUpdate from '../headers/PageHeaderImageBgUpdate'
import ProductLandingPageBannerSection from './ProductLandingPageBannerSection'

const ProductLandingPageAnotherSectionTwoWithBanner = () => (
  <>
    <div className='bg-white'>
      <PageHeaderImageBgUpdate>
        <div className='w-11/12 xl:max-w-screen-xl flex flex-col md:flex-row items-center'>
          <div className='w-full md:w-1/2'>
            <h1 className='font-body font-medium text-5xl xl:text-7xl text-white text-center md:text-left tracking-wider leading-tight'>
              MLRK1-AR
            </h1>
          </div>

          <div className='w-full md:w-1/2 flex items-center md:items-end jusitfy-center md:justify-end'>
            <StaticImage
              src="../../images/products/exit-device-kits/MLRK1-AR.png"
              width={420}
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="MLRK1-AR"
            />
          </div>
        </div>
      </PageHeaderImageBgUpdate>

      <div className='w-11/12 xl:max-w-screen-xl mx-auto mt-16'>
        <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-black mb-12'>Never stop improving.</h2>

        <p className='font-body text-xl md:text-2xl text-gray-900 font-bold md:leading-10 mt-6'>We’re committed to continuous improvement. We listened to customer feedback, monitored our tech calls, and decided it was time for a change.</p>

        <p className='font-body text-xl md:text-2xl text-gray-900 font-bold md:leading-10 mt-6'>Command Access customers: meet MLRK1-AR.</p>

        <p className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-6'>With this new version of the MLRK1, we’ve taken ownership of the quality control process so we can ensure that every product we sell features the Command Access level of quality we stand by.</p>

        <p className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-6'>Looking for a motorized latch-retraction kit for your Adams Rite storefront exit devices? Your search is over. The MLRK1-AR is a robust, straightforward field-installable motorized latch retraction kit for Adams Rite P88/88/86/84/82 series exit devices. It utilizes a CAT-designed custom assembly to maximize the pulling force. The only hole you’ll need to drill is the raceway! It uses our new 1-touch PTS “Push to Set” technology, making post-installation adjustments a breeze. An optional built-in REX switch is available to add onto all units!</p>
      </div>

      <div className='bg-white flex items-center justify-center pt-12 px-2'>
        <ProductLandingPageBannerSection>
          <div className='w-11/12 flex flex-col md:flex-row items-center'>
            <div className='w-full md:w-8/12'>
              <p className='font-body font-medium text-2xl xl:text-3xl text-white text-center md:text-left tracking-wider leading-tight mb-6'>
                Easier installation. Larger motor.
              </p>
              <p className='font-body font-medium text-2xl xl:text-3xl text-white text-center md:text-left tracking-wider leading-tight'>
                Discover the new and improved MLRK1-AR.
              </p>
            </div>

            <div className='w-full md:w-4/12 flex items-center md:items-end justify-center md:justify-end'>
              <StaticImage
                src="../../images/products/exit-device-kits/MLRK1-AR.png"
                width={210}
                quality={95}
                formats={["AUTO", "WEBP"]}
                alt="MLRK1-AR"
              />
            </div>
          </div>
        </ProductLandingPageBannerSection>
      </div>

      <div className='w-11/12 xl:max-w-screen-xl mx-auto'>
        <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-black mt-12 mb-12'>MLRK1-AR Overview</h2>
        <p className='font-body text-xl md:text-2xl text-gray-900 font-medium mt-6'>See what the MLRK1-AR is all about</p>
      </div>

      <div className='w-11/12 xl:max-w-screen-md mx-auto mt-10'>
        <div className='flex flex-col items-center justify-center'>
          <div className='w-full shadow-md'>
            <YoutubePlayerLite youtubeID='bJbtH2v7hdE' />
          </div>
        </div>
      </div>

      <div className='bg-white flex items-center justify-center py-24 px-2'>
        <a href='#contact' onClick={() => navigate('#contact')}>
          <button className="bg-red-900 hover:bg-red-800 font-display text-white text-lg uppercase font-bold mt-6 py-3 px-6 rounded tracking-widest mx-auto">
            <p className='font-body text-2xl md:text-3xl text-white text-center font-semibold'>Ready to learn more? Chat with us now.</p>
          </button>
        </a>
      </div>

      <div className='w-11/12 xl:max-w-screen-lg flex flex-col items-center justify-center xl:items-end xl:justify-end mx-auto mt-12'>
        <StaticImage
          src="../../images/sections/MLRK1-AR-installed.png"
          width={1024}
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="MLRK1-AR Installed"
        />

        <p className='font-body text-xl md:text-2xl text-gray-900 text-center md:text-right italic mt-4'>The MLRK1-AR installed in a standard exit device</p>
      </div>

      <div className='w-11/12 xl:max-w-screen-xl grid grid-cols-1 xl:grid-cols-2 gap-24 mx-auto mt-16 pb-16'>
        <div className='flex flex-col items-center justify-center xl:-mt-32'>
          
          <StaticImage
            src="../../images/products/exit-device-kits/MLRK1-AR.png"
            width={420}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="MLRK1-AR"
          />

          <p className='font-body text-xl md:text-2xl text-gray-900 italic'>The MLRK1-AR</p>
        </div>

        <div className='flex flex-col items-center justify-center xl:items-baseline xl:-mb-44'>
          
          <StaticImage
            src="../../images/sections/certification-badges.png"
            width={420}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="MLRK1-AR certifications"
          />

        </div>
      </div>

      <div className='bg-gray-100 pt-10 pb-14'>
        <div className='w-11/12 xl:max-w-screen-xl grid grid-cols-1 xl:grid-cols-2 gap-24 mx-auto'>
          <div>
            <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-black mb-12'>What’s New?</h2>

            <div>
              <ul className="list-disc ml-10">
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Easier installation</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Larger motor = stronger pulling power for vertical rods</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Push-to-set (PTS) smooth technology for easy post-installation adjustment of the latch</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Universal module that works across all our kits</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>True electronic dogging</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Energy conscious with our MM5 module</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Internal quality control so we can ensure a quality product every time</li>
              </ul>
            </div>
          </div>

          <div className='border-t-8 border-yellow-400 xl:border-0 pt-12 xl:pt-0'>
            <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-black mb-12'>What Hasn’t Changed?</h2>

            <div className='xl:border-l-8 xl:border-yellow-400 xl:-ml-10'>
              <ul className="list-disc ml-12 xl:ml-20">
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Made in the USA</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>3-year warranty</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>UL listed</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Field installable</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Optional built-in request-to-exit-switch</li>
                <li className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-4'>Exceptional and enthusiastic in-house customer support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-yellow-400 py-12'>
        <div className='w-11/12 xl:max-w-screen-lg mx-auto'>
          <h2 className='font-body text-3xl md:text-4xl text-gray-900 text-center font-black'>Committed To Continuous Improvement</h2>
        </div>
      </div>

      <div className='w-11/12 xl:max-w-screen-xl mx-auto pt-20'>
        <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-black'>MLRK1-AR Installation</h2>
      </div>

      <div className='w-11/12 xl:max-w-screen-md mx-auto mt-16 mb-24'>
        <div className='flex flex-col items-center justify-center'>
          <div className='w-full shadow-md'>
            <YoutubePlayerLite youtubeID='29MAM257pAE' />
          </div>
          <p className='font-body text-xl md:text-2xl text-gray-900 font-medium mt-6'>What’s got Mateo dancing this time?</p>
        </div>
      </div>

      <div className='w-11/12 xl:max-w-screen-xl mx-auto mt-16 mb-24'>
        <h2 className='font-body text-3xl md:text-4xl text-gray-900 font-black mb-12'>The MLRK1-AR saves you time, sweat, and money.</h2>

        <p className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-6'>The MLRK1-AR is the only electrification that doesn’t have limited compatibility or require a specialty power supply to work properly: it works on both rim and concealed vertical rod devices with no additional specialty power supply needed. With the MLRK1-AR, you’ve got the flexibility you need to be confident you’ve always got the right product on hand. You’re not locked in to just the OEM brand – you can use our MLRK1’s with most major manufacturers’ products.</p>
        <p className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-6'>The flexibility continues if you need a built-in request-to-exit switch: nothing separate to install, just another monitoring switch that’s there when you need it. Our updated module means it’s the same as our other kits: audible diagnostics, the same post-installation adjustment. No matter what manufacturers’ device you’re using, you can experience consistency with our MLRK1-AR. We’ve added a new motor to the MLRK1-AR. Larger motor = more torque = stronger pulling power for vertical rods.</p>
        <p className='font-body text-xl md:text-2xl text-gray-900 font-medium md:leading-10 mt-6'>With our MLRK1-AR, installation has never been easier. We revamped the mounting process from our previous model so that installation of MLRK1-AR requires less installation time than the previous model. Our easy-to-install motor-driven kits install in moments.</p>
      </div>

      <div id="contact" className='bg-red-900 py-12'>
        <div className='w-11/12 xl:max-w-screen-xl mx-auto'>
          <h2 className='font-body text-3xl md:text-4xl text-white font-black mb-12'>We’ve upped our game. Now you can up yours.</h2>

          <p className='font-body text-xl md:text-2xl text-white font-bold md:leading-10 mt-6'>With MLRK1, give your customers a superior product with less effort and cost.</p>
        </div>

        <div className='w-11/12 xl:max-w-screen-md mx-auto mt-10 mb-4'>
          <div className='flex flex-col items-center justify-center'>
            <div className='bg-white w-full shadow-md pt-8 px-8 pb-4'>
              <HubspotForm
                region='na1'
                portalId='9298297'
                formId='130c4261-0a37-4b96-91c3-f28fc2a5dbc2'
                loading={<div className='italic'>Loading contact form...</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default ProductLandingPageAnotherSectionTwoWithBanner