import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  opacity: none;
  width: 100%;
  height: 400px;

  @media (max-width: 767px) {
    height: 650px;
  }
`

const ProductLandingPageBannerSection = ({ children, imageData, opacity, height, position }) => {
  const data = useStaticQuery(graphql`
    query ProductLandingPageBannerSectionQuery {
      imageBg: file(relativePath: { eq: "header/hero image background.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 877)
        }
      }
    }
  `);

  const imageBg = getImage(data.imageBg);

  return (
    <div className="w-full grid relative" style={{ position: 'relative' }}>
      <StyledGatsbyImage
        alt=""
        image={imageData ? imageData : imageBg}
        formats={['auto', 'webp', 'avif']}
        width={1366}
        objectPosition={position ? position : 'top center'}
      />
      <div
        style={{
          // Apply the gradient overlay here
          position: 'absolute',
          top: 0,
          left: '50%', // Start the gradient from the middle of the image
          transform: 'translateX(-50%)', // Center the gradient horizontally
          width: '100%', // Make sure the gradient covers the whole image
          height: '100%', // Make sure the gradient covers the whole image
          backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
          content: '', // This is important for pseudo-elements
        }}
      ></div>
      <div
        style={{
          gridArea: '1/1',
          position: 'relative',
          placeItems: 'center',
          display: 'grid',
          zIndex: 2,
        }}
      >
        {children}
      </div>
    </div>
  );
};

ProductLandingPageBannerSection.propTypes = {
  children: PropTypes.node,
  imageData: PropTypes.object,
};

export default ProductLandingPageBannerSection;